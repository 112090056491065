<template>
    <section id="clients">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <h2 class="section-heading text-uppercase">{{ $t('select_nutrition_preferences') }}</h2>
                </div>
            </div>

            <div class="row">
                <div class="col-md-4 col-sm-6 portfolio-item" v-for="obj in preferences" :key="obj" v-on:click="selectPreference(obj.preference)">
                    <a class="portfolio-link" data-toggle="modal" v-bind:href="'#portfolioModal' + key">
                        <div class="portfolio-hover">
                            <div class="portfolio-caption">
                                <h4>{{ $t(obj.preference) }}</h4>
                            </div>
                        </div>
                        <img class="img-fluid" src="img/portfolio/02-thumbnail.jpg" alt="">
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'EvaluationTest',
        data() {
            return {
                body_type_id: null,
                preferences: [],
                preference: null,
            }
        },
        methods: {
            async getPreferences(body_type_id) {
                if(body_type_id == null) {
                    this.$router.push({
                        name: "evaluation",
                        params: {}
                    });
                    return
                }

                try {
                    let response = await this.$http.get(
                        process.env.VUE_APP_AI_TEA_TECH_BACKEND_HOST + "preferences?bodyTypeID=" + body_type_id,
                    );
                    this.preferences = response.data;
                } catch (error) {
                    console.log(error);
                }
            },

            async selectPreference(preference) {
                this.$router.push({
                    name: "tea",
                    params: { 'body_type_id': this.body_type_id, 'preference': preference}
                });
            }
        },
        created() {
            this.body_type_id = this.$route.params.body_type_id;
            this.getPreferences(this.body_type_id);
        }
    };
</script>
